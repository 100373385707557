/*Colors*/
$color-primary: #fffbfa;
$color-secondary-1: #f6fffd;
$color-secondary: #3d3d3d;
$color-tertiary: #515151;
$color-semantics-green: #0e9753;
$color-gray: #cccccc;
$color-orange: #ff411c;

/*Typography*/
$font1: "PT Serif";
$font2: "Degular";

/*Fonts*/
$weight1: 700;
$weight2: 400;

$size1: 48px;
$size2: 44px;
$size3: 40px;
$size4: 28px;
$size5: 20px;
$size6: 18px;
$size7: 16px;
$size8: 32px;
$size9: 24px;

.row-padd {
    //padding: 0 124px;
}
.banner-persona {
    background-image: url("../images/fondo-persona.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    //height: 40vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}
.row-carct {
    padding: 48px 124px;
}
.margin-window {
    overflow-x: hidden;
    //max-width: 1200px;
    //margin: auto;
}
.card-prod.ant-card .ant-card-body .ant-btn {
    //padding: 20px 16px;
}
.card-prod {
    border-color: #f8e2da;
    border-radius: 4px 20px;
    background: transparent;
}

.card-prod.ant-card .ant-card-body {
    padding: 20px 16px;
}

// .card-height {
//     height: 212px;
// }

.btn-producto-persona {
    border-radius: 4px 20px;
    background-color: #181818;
    color: #f7f7f7;
    font-family: "PT Serif";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
}

.btn-producto-persona:hover,
.btn-producto-persona:focus {
    background-color: #ff411c;
    color: #f7f7f7 !important;
    font-family: "PT Serif";
}

.card-preguntas-prod.ant-card .ant-card-body {
    padding: 20px;
}

.card-preguntas-prod {
    border-radius: 4px 20px;
    border-color: #f8e2da;
    background: transparent;
}

.card-preguntas-prod:hover {
    border-color: #ff411c;
}

.card-prod-persona {
    border: 1px #effffb !important;
    border-color: #effffb !important;
}

.cardProducto {
    .ant-card-cover > * {
        margin-top: 35px;
        margin-left: 4.5%;
        width: auto;
        height: auto;
    }

    background: #fffbfa;
    box-shadow: -2px 4px 12px 0px #18181814;
    height: 100%;
    position: relative;
    border-radius: 4px 20px;
    border: 2px solid #effffb;
}

.espaciado-prod-persona {
    margin-left: 40px;
    margin-right: 60px;
}

.margin-prod-pers {
    margin-left: 40px;
    margin-top: 60px;
}
.margin-prod-persona {
    margin-left: 40px;
}
.destacar {
    color: #f6fffd !important;
}

// .margin-type-plans {
//     margin-left: 30px;
// }

.margin-type-plans-details {
    margin-left: 40px;
    margin-right: 60px;
}

.image-per {
    width: 520.5px;
}

.card-details-product {
    padding: 48px 124px;
    width: 100%;
}
.margin-prod {
    margin-left: 40px;
    margin-right: 30px;
}
.margin-text {
    margin-left: 40px;
}

.image-prod-persona {
    //margin-top: -190px;
}
.title-persona {
    font-size: $size1;
    color: $color-primary;
    font-weight: $weight1;
    font-family: $font1;
}
.subtitle-persona {
    font-size: $size3;
    color: $color-primary;
    font-weight: $weight1;
    font-family: $font1;
}
.texto-persona {
    font-size: $size5;
    color: $color-primary;
    font-weight: $weight2;
    font-family: $font2;
    line-height: 24px;
    letter-spacing: 0.04em;
}

.title-persona,
.subtitle-persona {
    line-height: 44px;
    margin-bottom: 0; /* Elimina márgenes adicionales */
}

.row-caract {
    padding: 48px 124px;
}

//desktop
@media (min-width: 768px) {
    .row-details {
        padding: 48px 124px;
        padding-bottom: 0px;
    }
}

//mobile
@media (max-width: 767px) {
    .row-details {
        padding: 0 22px;
    }
}

.title-cards-persona {
    font-size: $size5;
    font-weight: $weight1;
    color: $color-secondary;
    margin-left: 10px;
    font-family: $font2;
}

.text-cards-persona {
    font-size: $size7;
    color: $color-secondary;
    font-family: $font2;
    letter-spacing: 0.04em;
}

.titulo-contrato {
    font-weight: $weight1;
    color: $color-secondary;
    font-family: $font1;
    font-size: $size3;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0.04em;
}
.texto-preg {
    font-size: 20px;
    font-family: "Degular";
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #515151;
}
.text-contratar {
    font-size: $size5;
    color: $color-tertiary;
    font-family: $font2;
    letter-spacing: 0.04em;
}
.questions {
    font-family: $font1;
    font-size: $size6;
    font-weight: $weight1;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $color-semantics-green !important;
    font-style: normal;
}
.space-text {
    margin-top: 22px;
}

.row-prod {
    padding: 48px 124px;
}

.title-prod {
    font-weight: $weight1;
    margin-bottom: 0;
    font-family: $font1;
    color: $color-secondary;
    font-size: $size3;
}
.subtitle-prod {
    font-size: $size5;
    font-family: $font2;
    color: $color-secondary;
}

.title-prod {
    line-height: 44px;
}

.subtitle-prod {
    line-height: 28px;
}

.text-details-prod {
    color: $color-secondary-1;
    font-size: $size5;
    font-family: $font2;
    letter-spacing: 0.04em;
}

.btn-detalle-prod {
    border-radius: 4px 20px 4px 20px;
    width: 300px;
    height: 44px;
    padding: 12px 24px;
    font-size: $size7;
    font-weight: $weight1;
    font-family: $font1;
}

.btn-volver {
    background: transparent;
    border: 2px solid #ff411c;
    color: $color-primary;
}
.btn-contrato {
    background: $color-gray !important;
    border-color: $color-gray !important;
    color: $color-tertiary !important;
}
.btn-folleto {
    background: transparent;
    border: 2px solid $color-primary;
    color: $color-primary;
}
.text-types-plans {
    font-size: $size8;
    color: $color-primary;
    font-weight: $weight1;
    font-family: $font1;
    line-height: 36px;
    letter-spacing: 1.28px;
}
.typography-text {
    color: $color-secondary;
    pointer-events: none;
    font-size: $size7;
    font-family: $font2;
    letter-spacing: 0.04em;
    display: block;
    padding-top: 20px;
}
.card-detail-prod {
    background-color: $color-secondary;
}

.tag-details {
    padding: 5.8px 12px;
    font-size: 14px;
    color: $color-orange;
    font-weight: $weight1;
    border-radius: 4px 20px 4px 4px;
    font-family: $font1;
    letter-spacing: 0.04em;
}
.padd-banner {
    padding: 86.5px 0 86.5px 124px;
}

.loader-prod {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    min-width: 700px;
}

.cardProducto.ant-card .ant-card-body {
    padding: 16px 24px;
}

.link-contacto:hover,
.link-contacto:focus {
    color: #ff411c !important;
}

.link-contacto {
    color: #0e9753 !important;
    font-weight: 700;
    font-family: "PT Serif";
    font-size: 20px;
}

.titulo-detalle {
    font-family: "PT Serif";
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f6fffd;
}
.avatar {
    background-color: #ff411c;
    //marginRight: 10;
    color: #fffbfa;
    width: 45px;
    height: 45px;
}
.texto-negrita {
    color: #16d480 !important;
}
.texto-negrita:hover,
.texto-negrita:focus,
.texto-negrita:focus-visible {
    color: #ff411c !important;
}

.tipos {
    padding-bottom: 40px;
}

@media (max-width: 767px) {
    .link-contacto {
        font-size: 16px;
    }
    .questions {
        font-family: "PT Serif";
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.04em;
    }
    .ant-layout-header {
        height: 80px;
        padding: 0 50px;
        color: rgba(0, 0, 0, 0.88);
        line-height: 40px;
        background: #181818;
    }
    .padd-banner {
        padding: 0;
    }
    .title-persona,
    .subtitle-persona {
        line-height: 32px;
        letter-spacing: 0.04em;
        margin-bottom: 0; /* Elimina márgenes adicionales */
    }

    .row-caract {
        padding: 22px 24px;
    }

    .card-height {
        margin-bottom: 12px;
        max-height: 100%;
        overflow: hidden;
    }

    .imgPersonaMobile {
        margin-bottom: 20px;
    }
    // .cardProducto{
    //     width: auto;
    // }
    .topcardsmobile {
        margin-left: 20px;
        margin-right: 20px;
    }
    .margin-prod-pers {
        margin-left: 20px;
        margin-top: 20px;
    }
    .margin-prod-persona {
        margin-left: 20px;
    }
    .espaciado-prod-persona {
        margin-left: 20px;
        margin-right: 20px;
    }
    // .margin-type-plans {
    //     margin-left: 20px;
    //     margin-right: 20px;
    // }
    .image-per {
        width: 100%;
    }
    .card-details-product {
        padding: 20px 22px;
        width: 100%;
    }
    .margin-prod {
        margin-left: 20px;
        margin-right: 20px;
    }
    .margin-text {
        margin-left: 20px;
    }
    /*.img-producto-persona-mobile {
        width: 100%;
        background-image: url("../images/mobile-persona.png");
        background-position: 100% 91.7%;
        background-repeat: no-repeat;
    }*/
    .banner-persona {
        background-image: url("../images/fondo-persona-mobile.png");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        //height: 40vh;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .title-persona {
        font-size: 28px;
    }
    .subtitle-persona {
        font-size: 22px;
    }
    .texto-persona {
        font-size: 16px;
    }
    .banner-persona {
        //height: 50vh;
        padding: 16px 0 80px 20px;
    }

    .hoverLink:focus,
    .hoverLink:hover {
        color: #ff411c !important;
    }

    .titulo-contrato {
        font-weight: $weight1;
        color: $color-secondary;
        font-family: $font1;
        font-size: 24px;
        padding-bottom: 12px;
    }
    .text-contratar {
        font-size: $size7;
    }
    .row-prod {
        padding: 22px 24px;
    }

    .cardProducto {
        .ant-card-cover > * {
            margin-top: 35px;
            margin-left: 4.5%;
            width: auto;
            height: auto;
        }

        box-shadow: -2px 4px 12px 0px #18181814;
        width: auto;
        height: 100%;
        position: relative;
        border-radius: 4px 20px;
        border: 2px solid #effffb;
    }
    .loader-prod {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        min-width: 0;
    }
    .title-prod {
        font-weight: $weight1;
        font-family: $font1;
        color: $color-secondary;
        font-size: $size4;
    }

    .mobile.subtitle-prod {
        line-height: 24px;
        letter-spacing: 0.04em;
        font-size: $size7;
        font-family: $font2;
        color: $color-secondary;
    }
    .text-cards-persona {
        font-size: 14px;
    }
    .texto-preg {
        font-size: 16px;
        font-family: "Degular";
        line-height: 24px;
        letter-spacing: 0.04em;
        text-align: left;
    }
    .card-prod-pe.ant-card .ant-card-body {
        padding: 0;
    }
    .img-mobile {
        width: 100%;
    }
    .btn-detalle-prod {
        width: 100%;
        height: 40px;
    }
    .text-types-plans {
        font-family: "PT Serif";
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .titulo-detalle {
        font-family: "PT Serif";
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #f6fffd;
    }
    .text-details-prod {
        font-family: Degular;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .avatar {
        width: 36px;
        height: 36px;
    }
    .tipos {
        padding-bottom: 24px;
    }
}

.paddin {
    padding-top: 20px;
}

.card-detail-prod-inno {
    /* padding: 18px;
    background-color: #3d3d3d;
    border-color: #515151;*/
}
.card-detail-prod-inno.ant-card .ant-card-body {
    //padding-bottom: 0px;
}
.card-prod-pe {
    background-color: transparent;
    border: 0;
}
.card-detail-prod,
.card-prod-pe.ant-card .ant-card-body {
    padding: 0;
}

//acaa
.card-detail-prod {
    .ant-card-body {
        padding: 20px;
        p {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

.name {
    color: #fffbfa;
    font-family: "Degular";
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0;
}
.uf {
    color: #fffbfa;
    font-family: "PT Serif";
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.title-plan {
    color: #fffbfa;
    font-family: "Degular";
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.text-plan {
    color: #fffbfa;
    font-family: "Degular";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.text-plan-r {
    color: #fffbfa;
    font-family: Degular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
