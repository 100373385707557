/*Colors*/
$color-primary: #fffbfa;
$color-secondary-1: #f6fffd;
$color-secondary: #3d3d3d;
$color-tertiary: #515151;
$color-semantics-green: #0e9753;
$color-gray: #cccccc;
$color-orange: #ff411c;

/*Typography*/
$font1: "PT Serif";
$font2: "Degular";

/*Fonts*/
$weight1: 700;
$weight2: 400;

$size1: 48px;
$size2: 44px;
$size3: 40px;
$size4: 28px;
$size5: 20px;
$size6: 18px;
$size7: 16px;
$size8: 32px;

.hoverLink:focus,
.hoverLink:hover {
    color: #ff411c !important;
}

.banner-empresa {
    background-image: url("../images/fondo-empresa.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    //height: 60vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.card-height-empresa {
    height: 300px;
    margin-bottom: 7px;
}

.card-detail-prod {
    border-radius: 4px 20px;
    margin-bottom: 40px;
}
.margin-tops {
    margin-top: 30px;
}

.btn-onboarding-empresa {
    background: #181818;
    border: 2px solid #ff411c;
    border-radius: 4px 20px 4px 20px;
    color: $color-text;
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    font-family: "PT Serif";
}

.btn-onboarding-empresa:hover {
    background: #ff411c;
    color: #f7f7f7 !important;
}
.destacar {
    color: #f6fffd !important;
    font-weight: bold;
}
.image-emp {
    width: 500px;
}
.espaciado-prod {
    margin-left: 40px;
    margin-right: 30px;
}
.image-prod-empresa {
    //margin-top: -200px;
}

.title-prod {
    color: #3d3d3d;
    font-family: "PT Serif";
    font-size: 40px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
}

.title-carac {
    font-family: "PT Serif";
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
}

.detalle-producto {
    font-family: "PT Serif";
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
}

.marge-mobile {
    margin-top: 0px;
    padding-right: 10px;
}
.margem-mobile {
    margin-top: 20px;
}

.estilos-mercado {
    font-family: "Degular";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #fffbfa;
}

.estilos-textos {
    font-family: "Degular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #fffbfa;
}

.texto-plan {
    margin-left: -10px;
}

@media (max-width: 767px) {
    .texto-plan {
        margin-left: 0px;
    }
    .categorias {
        margin-top: 20px;
    }
    .margem-mobile {
        margin-top: 8px;
    }
    .marge-mobile {
        margin-top: -5px;
    }
    .detalle-producto {
        font-family: "PT Serif";
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
    }
    .title-carac {
        font-family: "PT Serif";
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
        color: #3d3d3d;
        margin-bottom: 24px;
    }
    .padd-prod-card {
        padding-top: 8px;
    }
    .title-prod {
        color: #3d3d3d;
        font-family: PT Serif;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
    }
    .image-emp {
        width: 370px;
    }
    .espaciado-prod {
        margin-left: 20px;
        margin-right: 20px;
    }
    .margin-tops {
        margin-top: 20px;
    }
    .mobile {
        height: 480px;
    }

    .banner-empresa {
        background-image: url("../images/fondo-empresa-mobile.png");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        //height: 40vh;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .banner-empresa {
        padding: 16px 0 80px 20px;
    }
    .card-prod-pe {
    }
    .btn-onboarding-empresa {
        height: 40px;
    }
}

.containerDescripcionInnoLab {
    // desktop
    @media (min-width: 1200px) {
        height: 200px;
    }
}

.link-e {

    font-family: 'PT Serif';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04em;

}
