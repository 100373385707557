
@font-face {
  font-family: 'Degular';
  src: url('./assets/fonts/Degular-Regular.otf') format('opentype');
  font-style: normal;
}

::-webkit-scrollbar {
  height: 12px !important;
  width: 12px  !important;
  background: #001529d9  !important;
}


::-webkit-scrollbar-thumb {
  background: #001529d9 !important;
  border-radius: 1ex !important;
  border-color: #595959d9;
  border-style: solid !important;
  border-width: 2px !important;
}
  
::-webkit-scrollbar-track {
  border-radius: 0px !important;
  background: #001529d9  !important;
}

.ant-modal-root .ant-modal-mask {
  background: #181818C7;
}

.ant-btn:focus-visible,
.ant-menu:focus-visible,
.ant-btn:focus-visible,
a:focus-visible,
span:focus-visible{
  outline:inherit !important;
}