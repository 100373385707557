.logo-footer {
    width: 128px;
}

.footer-producto {
    padding: 48px 124px;
}

.term:hover,
.term:focus {
    color: #ff411c !important;
}

.term-text {
    color: #fffbfa;
    font-family: Degular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.text-active {
    color: #ff411c;
    font-weight: 700;
    padding-bottom: 5px;
    transition: "all 0.3s ease";
}
.text-no-active {
    color: #fffbfa;
    font-weight: 700;
    padding-bottom: 5px;
    transition: "all 0.3s ease";

    a {
        color: red !important;
        text-decoration: none;
        background-color: transparent;
        outline: none;
        cursor: pointer;
        transition: color 0.3s;
        -webkit-text-decoration-skip: objects;
    }
}

.text-no-active a {
    color: red !important;
}
//desktop
@media (min-width: 768px) {
    .row-footer {
        padding-left: 124px;
        padding-right: 124px;
        margin-bottom: 60px;
        padding-top: 40px;

        .version-mobile {
            display: none;
        }

        .footer-text {
            color: #fffbfa;
            font-family: "Degular";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.8px;
        }
    }
}

.option-menu-footer:hover,
.option-menu-footer:focus {
    color: #ff411c !important;
}

//mobile
@media (max-width: 767px) {
    .row-footer {
        margin-bottom: 16px;
        padding-left: 20px;
        padding-right: 20px;

        .version-dektop {
            display: none;
        }

        .footer-text {
            color: #fffbfa;
            font-family: "Degular";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.8px;
        }
    }
    .logo-footer {
        width: 142px;
    }
    .footer-producto {
        padding: 22px 20px;
    }
    .term-link {
        color: #16d480;
        font-family: Degular;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.04em;
        margin-left: -15px;
    }
}

.circle-redes {
    background-color: #f7f7f7;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle-redes:hover,
.circle-redes:focus-visible,
.circle-redes:focus {
    background-color: #ff411c !important;
}

.circle-redes:hover .hover-svg path {
    fill: #f7f7f7;
}

.circle-redes:focus .hover-svg path {
    fill: #f7f7f7;
}

.circle-redes:focus-visible .hover-svg path {
    fill: #f7f7f7;
}

.hover-svg path {
    fill: #ff411c; //color inicial
}

.productos-desktop {
    font-weight: 700 !important;
    margin-left: 60px !important;
    padding-left: 0px !important;
}
