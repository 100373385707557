.margin-contact {
    //margin-left: 30px;
    //margin-right: 30px;
}

.row-contact {
    padding: 48px 124px;
}

.card-form.ant-card .ant-card-body {
    padding: 32px;
}

.scroll {
    max-height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ff3700 #fff;
    border-radius: 4px;
}

.scroll-container {
    overflow: auto;
    //border: 1px solid #ccc;
    border-radius: 8px;
    max-height: 400px;
    //scrollbar-color: #FF3700 #fff;
}
.scroll-container::-webkit-scrollbar {
    width: 8px;
}
.scroll-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 25px;
}
.scroll-container::-webkit-scrollbar-track {
    background: transparent;
}

.title-contact {
    color: #f6fffd;
    font-family: "PT Serif";
    font-size: 48px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0.04em;
}
.subtitle-contact {
    color: "#F6FFFD";
    font-family: "PT Serif";
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.04em;
}

.text-contact {
    color: "#F6FFFD";
    font-family: "Degular";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
}

.title-questions {
    color: #fffbfa;
    font-family: "PT Serif";
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.04em;
}
.text-questions {
    color: #f4f4f4;
    font-family: "Degular";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
}
.titulo-formulario {
    font-family: "PT Serif";
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.04em;
}
.subtitulo-formulario {
    font-family: "Degular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #3d3d3d;
}
.divider-contact {
    display: block;
}
.divider-contact.ant-divider {
    border-block-start: 1px solid #7a7a7a;
}

.ant-input-outlined {
    height: 48px;
}

.ant-select.ant-select-in-form-item {
    height: 48px;
}

.ant-form-item .ant-form-item-label > label {
    color: #515151;
    font-family: PT Serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
}

.ant-input {
    font-family: "Degular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
}

/*.ant-input:focus {
    border-color: #FF411C;
}*/
.formulario {
    border-radius: 4px 20px;
}
.formulario.ant-card .ant-card-body {
    padding: 32px;
}
.term {
    color: #16d480;
    font-family: "Degular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
}
.terminos-text {
    color: #3d3d3d;
    font-family: "Degular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
}
.term-bold {
    color: #0e9753 !important;
    font-family: "Degular";
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
}

.term-bold:hover,
.term-bold:focus,
.term-bold:focus-visible {
    color: #ff411c !important;
}

.btn-onboarding-c {
    background-color: $background;
    color: #515151;
    border-radius: 4px 20px 4px 20px;
    font-family: "PT Serif";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
    width: 200px;
    height: 44px;
}
.btn-onboarding-c:hover {
    background-color: $color-primary;
    color: $color-text;
}
.btn-onboarding-g:hover {
    background-color: $color-primary;
    color: $color-text;
}

.btn-contacto {
    width: 200px;
    height: 44px;
    border-radius: 4px 20px 4px 20px;
}
.ant-btn-variant-solid {
    color: #fff;
    background: #181818;
}

.ant-btn {
    font-family: "PT Serif";
    font-size: 16px;
    font-weight: 700;
}

.dropdown-drpTipoConsulta.ant-form-item-with-help .ant-form-item-explain {
    background-color: #ff8282;
    border-radius: 4px 4px 4px 20px;
    min-height: 36px;
    //padding: 5px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: "Degular";
    color: #3d3d3d;
}

/*.ant-input-status-success {
    border-color: #515151;
    color: #515151;
}*/

/*.ant-select .ant-select-status-success {
    color: #515151;
    border-radius: 4px 20px;
    height: 50px;
}*/

.dropdown-drpTipoConsulta {
    color: #7a7a7a;
    font-size: 16px;
    font-family: "Degular", sans-serif;
    background-color: #f8e2da !important;
    line-height: 24px;
    letter-spacing: 0.04em;
}

.dropdown-drpTipoConsulta .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #515151;
    background-color: #effffb !important;
}

.dropdown-drpTipoConsulta .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #effffb !important;
}

.dropdown-drpTipoConsulta .rc-virtual-list-scrollbar-thumb {
    background: #ff411c !important;
}

.dropdown-drpTipoConsulta .ant-select-item {
    color: #7a7a7a;
}

#aceptaTerminos_help {
    display: none;
}
.ant-form-item-with-help .ant-form-item-explain {
    background-color: #ff8282;
    border-radius: 4px 4px 4px 20px;
    min-height: 36px;
    //padding: 5px 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: "Degular";
    color: #3d3d3d;
}
.ant-form-item .ant-form-item-explain-error {
    margin-top: 5px;
}
.ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #ff411c;
}
.card-preguntas.ant-card .ant-card-body {
    padding: 20px;
}
.typography-text-c {
    font-family: "Degular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
}
.link-d {
    color: #1afd8f !important;
    font-family: "Degular";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04em;
}
.link-d:hover,
.link-d:focus,
.link-d:focus-visible {
    color: #ff411c !important;
}

.title-question {
    font-family: "PT Serif";
    font-size: 40px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.04em;
}
.text-question {
    font-family: "Degular";
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
}
.ant-select .ant-select-selection-placeholder {
    font-size: 16px;
    color: #a3a3a3;
    font-family: "Degular";
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
}

.padd-1 {
    padding-bottom: 12px;
}
.padd-2 {
    padding-bottom: 24px;
}
.divider-contact::after {
    content: "";
    //position: relative;
    //right: 0; /* Alineado al final */
    //bottom: 0;
    width: 8px; /* Ancho de la línea naranja */
    height: 4px; /* Grosor de la línea naranja */
    background-color: #ff411c; /* Color de la línea naranja */
    border-radius: 0px 0px 5px 5px;
    float: right;
}
.marginCheck {
    margin-top: -30px;
}
@media (max-width: 767px) {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        padding-inline-end: 68px;
    }
    .ant-select .ant-select-selection-placeholder {
        white-space: break-spaces;
    }
    .ant-select.ant-select-in-form-item {
        height: 68px;
    }
    .texto-check-h {
        font-family: "Degular";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #3d3d3d;
        position: relative;
        top: 0px !important;
    }
    .icon-col {
        align-self: flex-start;
        justify-content: flex-end;
        margin-top: 4px;
    }
    .row-contact {
        padding: 22px 20px;
    }
    .title-contact {
        color: #f6fffd;
        font-family: "PT Serif";
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
    }
    .subtitle-contact {
        font-family: "PT Serif";
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
    }
    .text-contact {
        font-family: "Degular";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
    }
    .formulario.ant-card .ant-card-body {
        padding: 30px 14px;
    }
    .titulo-formulario {
        font-family: "PT Serif";
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
    }
    .subtitulo-formulario {
        font-family: "Degular";
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.04em;
    }
    .ant-input-outlined {
        height: 44px;
    }

    .btn-contacto {
        height: 40px;
    }
    .title-question {
        font-family: "PT Serif";
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
    }
    .text-question {
        font-family: "Degular";
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
    }
    .padd-1 {
        padding-bottom: 10px;
    }
    .padd-2 {
        padding-bottom: 24px;
    }
    .marginCheck {
        margin-top: -10px;
    }
    .padd-text-check {
        margin-left: 5px;
    }
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label > label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-24.ant-form-item-label > label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-xl-24.ant-form-item-label > label {
    padding-left: 12px;
}

.formulario.ant-card {
    font-family: "Degular";
    background: #fffbfa;
}
.texto-check {
    font-family: "Degular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #3d3d3d;
}
.texto-check-h {
    font-family: "Degular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #3d3d3d;
    position: relative;
    top: -2px;
}
.ant-input-status-success {
    border-color: #7a7a7a;
    color: #515151;
}
.ant-select .ant-select-arrow {
    color: #666666;
}
.ant-input:hover {
    border: 2px solid #ff411c;
    background-color: #f4f4f4;
}
.ant-select {
    .ant-select-selector {
        &:hover {
            border: 2px solid #ff411c;
        }
    }
}
.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
    border: 2px solid #ff411c;
    box-shadow: none;
}
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 2px solid #ff411c;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 2px solid #ff411c;
    outline: 0;
}
.ant-select-status-success {
    .ant-select-selector {
        border-color: #7a7a7a !important;
        color: #515151 !important;
    }
}
.ant-select-status-success .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector {
    font-size: 16px;
    color: #515151 !important;
    font-family: "Degular";
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
}
.ant-select-status-success.ant-select-open.ant-select-focused .ant-select-selector {
    border: 2px solid #ff411c;
}
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
    border-color: #ffa39e;
    background-color: #ffd3d3;
}

.padd-text-check {
    margin-left: -15px;
}
.ant-input.custom-placeholder::placeholder {
    color: #a3a3a3;
    font-family: "Degular";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.04em;
}
.ant-space-item {
    margin-top: -2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    border: 1px solid #7a7a7a;
    -webkit-text-fill-color: #515151;
    -webkit-box-shadow: 0 0 0px 1000px #f4f4f4 inset;
    transition: background-color 5000s ease-in-out 0s;
}

/*Checkbox */
input[type="checkbox"] {
    display: none;
}

.checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #ff411c;
    border-radius: 0;
    background-color: transparent;
    border-radius: 4px;
}

input[type="checkbox"]:checked + .checkmark {
    background-color: #0e9753;
    border-color: #0e9753;
}

input[type="checkbox"]:checked + .checkmark::after {
    content: "";
    position: absolute;
    display: block;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #fffbfa;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark:hover {
    background-color: transparent;
}

.checkmark:hover::before {
    content: "";
    position: absolute;
    top: -8px;
    left: -8px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #f8e2da;
    opacity: 0.3;
    transition: all 0.2s ease-in-out;
}
