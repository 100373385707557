// desktop
@media (min-width: 768px) {
    .logo-nuam {
        width: 142px;
        height: 32px;
    }

    .text-selector-tipo {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #fffbfa;
        font-family: "Degular";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        letter-spacing: 0.8px;
    }

    .img-modal-seletor {
        margin-bottom: 10px;
    }

    .img-pais {
        width: 44px;
        height: 44px;
    }

    .pais-text {
        color: #fffbfa;
        font-size: 32px;
        font-weight: 700;
        font-family: "PT Serif";
        margin-left: 22px;
    }

    .margin-pais {
        height: 500px;
        margin-top: 170px;
        padding-left: 116px;
        padding-right: 116px;

        .margin-pais-selector {
            margin-left: 48px;

            .ant-card-body {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 36px;
                padding-right: 36px;
            }
        }

        .card-selector-pais {
            border: 2px solid $color-primary;
            background: none;
            border-radius: 4px 20px 4px 20px;
        }

        .card-pais-selector {
            width: 100%;
            box-sizing: border-box;
            border: 2px solid $color-primary;
            background: none;
            margin-bottom: 32px;
            border-radius: 4px 20px 4px 20px;

            .ant-card-body {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 36px;
                padding-right: 36px;
            }
        }

        * .title-register {
            color: #fffbfa;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            font-family: "PT Serif";
            line-height: 52px;
            letter-spacing: 1.92px;
        }

        .subtitle-register {
            color: #fffbfa;
            font-size: 32px;
            font-family: "PT Serif";
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 1.28px;
        }

        .text-register {
            color: #fffbfa;
            font-size: 20px;
            font-family: "Degular";
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.8px;
        }

        .btn-onboarding-pais-seleccion {
            width: 200px;
            border-radius: 4px 20px;
            background-color: $background;
            border: 2px solid #ff411c;
            color: #fffbfa;
            font-size: 16px;
            font-family: "PT Serif";
            font-weight: bold;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            padding-right: 24px;
            gap: 12px;
            height: 44px;
        }

        .btn-onboarding-pais-seleccion-disabled {
            border-radius: 4px 20px;
            width: 200px;
            background-color: $background-disabled !important;
            color: #515151 !important;
            font-size: 16px;
            font-family: "PT Serif";
            font-weight: bold;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            padding-right: 24px;
            gap: 12px;
            height: 44px;
        }

        .card-pais {
            height: 100%;
        }
    }

    //desk
    .tooltip-pais {
        .ant-tooltip-arrow {
            bottom: -13px !important;
        }

        .ant-tooltip-content {
            top: 14px;
        }

        .ant-tooltip-content {
            .ant-tooltip-inner {
                padding: 16px;
                border-radius: 8px;
                font-family: "Degular";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.56px;
                background-color: #181818;
                box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
                color: #F7F7F7;
            }
        }
    }

    .hover-effect:hover path {
        stroke: #ff411c !important;
    }

    .separador {
        margin-left: 24px;
        margin-right: 24px;
        width: 24px;
    }
}

//mobile
@media (max-width: 767px) {
    .last-card {
        margin-bottom: 46px !important;
    }

    .logo-nuam {
        width: 80px;
        height: 20px;
    }

    .text-selector-tipo {
        margin-top: 0px;
        margin-bottom: 0px;
        color: #fffbfa;
        font-family: "Degular";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.8px;
    }

    .img-modal-seletor {
        margin-bottom: 16px;
    }

    .modal-registro {
        .ant-modal-content {
            margin-left: 18px;
            margin-right: 18px;
        }
    }

    .img-pais {
        width: 36px;
        height: 36px;
    }

    .pais-text {
        color: #fffbfa;
        font-family: "PT Serif";
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 1.12px;
        margin-left: 22px;
    }

    .container-card-pais {
        margin-bottom: 24px;
    }

    .margin-pais {
        padding-top: 16px;
        // margin-bottom: 48px;
        padding-left: 20px;
        padding-right: 20px;

        .margin-pais-selector {
            margin-left: 48px;

            .ant-card-body {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 36px;
                padding-right: 36px;
            }
        }

        .card-selector-pais {
            border: 2px solid $color-primary;
            background: none;
            border-radius: 4px 20px 4px 20px;
        }

        .card-pais-selector {
            margin-bottom: 24px;
            width: 100%;
            box-sizing: border-box;
            border: 2px solid $color-primary;
            background: none;
            border-radius: 4px 20px 4px 20px;

            .ant-card-body {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 36px;
                padding-right: 36px;
            }
        }

        .title-register {
            color: #fffbfa;
            font-family: "PT Serif";
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 114.286% */
            letter-spacing: 1.12px;
        }

        .subtitle-register {
            color: #fffbfa;
            font-family: "PT Serif";
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 145.455% */
            letter-spacing: 0.88px;
        }

        .text-register {
            color: #fffbfa;
            font-family: "Degular";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.64px;
        }

        .btn-onboarding-pais-seleccion {
            width: 100%;
            border-radius: 4px 20px;
            background-color: $background;
            border: 2px solid #ff411c;
            color: #fffbfa;
            font-size: 16px;
            font-family: "PT Serif";
            font-weight: bold;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            padding-right: 24px;
            gap: 12px;
            height: 40px;
        }

        .btn-onboarding-pais-seleccion-disabled {
            width: 100%;
            border-radius: 4px 20px;
            background-color: $background-disabled !important;
            color: #515151 !important;
            font-size: 16px;
            font-family: "PT Serif";
            font-weight: bold;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            padding-right: 24px;
            gap: 12px;
            height: 40px;
        }

        .card-pais {
            height: 100%;
        }
    }

    .tooltip-pais {
        .ant-tooltip-arrow {
            bottom: -25px !important;
        }

        .ant-tooltip-content {
            top: 26px;
        }

        .ant-tooltip-content {
            .ant-tooltip-inner {
                padding: 10px 14px;
                border-radius: 8px;
                font-family: "Degular";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0.56px;
                background-color: #181818;
                box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .hover-effect:hover path {
        stroke: #ff411c !important;
    }

    .separador {
        visibility: hidden;
        height: 44px;
    }
}
