$background: #181818;
$color-primary: #ff411c;
$color-text: #fffbfa;
$grayscale-600: #7a7a7a;
$grayscale-900: #3d3d3d;
$background-disabled: #cccccc;
$text-disbled: #515151;
$tooltip-border: #ff411c;
$color-card-from: #fffbfa;
$input-label: #515151;
$required: #ff411c;
$input-color: #f4f4f4;
$color-button-text: #f7f7f7;

body {
    background-color: $background;
    margin: 0;
    padding: 0;
}

.logo-menu-container {
    display: flex;
    align-items: center;
    margin: auto;
    max-width: 1200px;
}

.menu-web {
    font-size: 18px;
    font-family: "PT serif";
    font-weight: bold;
}

.ant-menu .ant-menu-title-content:hover,
.ant-menu .ant-menu-title-content:focus,
.ant-menu .ant-menu-title-content:focus-visible {
    color: #ff411c;
}

.spanHeader:focus-visible,
.spanHeader:focus-visible {
    color: #ff411c;
}

.ant-menu-title-content:focus-visible {
    color: #ff411c;
}

.ant-menu-title-content {
    a {
        color: #fffbfa;
        font-family: "PT Serif";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.72px;
    }
}

.submenu-popup {
    &.ant-menu-submenu-popup .ant-menu-vertical.ant-menu-sub {
        min-width: 130px !important;
    }
}

.menu-web-selected-empresa {
    .ant-menu-item-only-child:nth-child(2) {
        .ant-menu-title-content {
            color: #ff411c;
        }
    }
}

.menu-web-selected-persona {
    .ant-menu-item-only-child:nth-child(1) {
        .ant-menu-title-content {
            color: #ff411c;
        }
    }
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark > .ant-menu .ant-menu-item,
.ant-menu-dark .ant-menu-submenu-title,
.ant-menu-dark > .ant-menu .ant-menu-submenu-title {
    color: #fffbfa;
}

.card-pais {
    border: 2px solid $color-primary;
    background: none;
    border-radius: 4px 20px 4px 20px;
    /*width: 627px;
    height: 344px;*/
}
.card-pais.ant-card .ant-card-body {
    padding: 36px;
}
.card-seleccion {
    border: 2px solid $color-primary;
    background: none;
    border-radius: 4px 20px 4px 20px;
    /* width: 533px;*/
    height: 100px;
}

.card-seleccion.ant-card .ant-card-body {
    padding: 20px 36px;
}

.card-preguntas {
    border-color: $color-primary;
    background: none;
    border-radius: 4px 20px 4px 20px;

    .ant-card .ant-card-body {
        padding: 14px;
    }
}

.card-seleccion-disabled {
    border-color: $background-disabled;
    background: none;
    border-radius: 4px 20px 4px 20px;
    /*width: 533px;*/
    height: 100px;
}
.card-seleccion-disabled.ant-card .ant-card-body {
    padding: 20px 36px;
}

.header-onboarding {
    background: $background;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #fff;
    background-color: $background;
}

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline,
.ant-menu-dark > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: $background;
}

.btn-onboarding-g {
    background: transparent;
    border: 2px solid $color-primary;
    border-radius: 4px 20px 4px 20px;
    color: $color-button-text;
    font-family: "PT Serif";
    width: 149px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
}

.btn-onboarding-seleccion {
    border-radius: 4px 20px;
    background-color: $background;
    border: 2px solid $color-primary;
    color: $color-card-from;
    width: 200px;
    height: 44px;
    font-size: 16px;
    font-family: "PT Serif";
    font-weight: bold;
}
.btn-onboarding-seleccion-disabled {
    border-radius: 4px 20px;
    background-color: $background-disabled !important;
    color: $input-label !important;
    width: 200px;
    height: 44px;
    font-size: 16px;
    font-family: "PT Serif";
    font-weight: bold;
}

::marker {
    color: #ff411c;
}

.btn-onboarding-p {
    background: transparent;
    border: 2px solid $color-card-from;
    border-radius: 4px 20px 4px 20px;
    color: $color-text;
    font-family: "PT Serif";
}

.btn-onboarding-p:hover {
    background: transparent;
    border: 2px solid $color-card-from !important;
    color: $color-text !important;
}

.btn-onboarding-disabled-2 {
    background: #cccccc !important;
    border: 2px solid #cccccc;
    border-radius: 4px 20px 4px 20px;
    color: #515151 !important;
    font-family: "PT Serif";
}

.btn-onboarding {
    background: transparent;
    border: 2px solid $color-primary;
    border-radius: 4px 20px 4px 20px;
    color: $color-text;
    width: 233px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    font-family: "PT Serif";
    line-height: 20px;
    letter-spacing: 0.04em;
}

.btn-onboarding:hover,
.btn-onboarding:focus {
    background: $color-primary;
    color: $color-text !important;
}

.btn-onboarding-m {
    background: $background;
    border: 2px solid $color-primary;
    border-radius: 4px 20px 4px 20px;
    color: $color-text;
    font-family: "PT Serif";
    width: 195px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
}

.btn-onboarding-m:hover {
    background: $background;
    color: $color-text;
}

.btn-onboarding-persona {
    background: transparent;
    border-color: $color-text;
    border-radius: 4px 20px 4px 20px;
    color: $color-text;
    width: 229px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    color: #fffbfa;
    border-color: #fffbfa;
    font-family: "PT Serif";
    line-height: 20px;
    letter-spacing: 0.04em;
}
.btn-onboarding-persona:focus,
.btn-onboarding-persona:hover {
    background: $color-primary;
    color: $color-text !important;
}

.btn-onboarding-disabled {
    background-color: $background-disabled !important;
    border-radius: 4px 20px 4px 20px;
    color: $text-disbled !important;
    width: 100%;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
    font-family: "PT Serif";
}

.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
    cursor: not-allowed;
    border-color: #cccccc;
    color: #515151;
    background-color: #cccccc;
    box-shadow: none;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):focus {
    color: $color-text;
    border-color: $color-primary;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: none;
    border-color: transparent;
}

.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-solid:not(:disabled):not(.ant-btn-disabled):focus-visible {
    background: $color-primary;
    color: $color-text;
}

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):focus,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):focus {
    color: $color-text;
    border-color: $color-primary;
    background-color: $background;
}
.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):not(.ant-btn-circle):focus-visible,
.ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):not(.ant-btn-circle):focus-visible {
    color: $color-text;
    border-color: $color-primary;
    background: $color-primary;
}

.modal-registro.ant-modal .ant-modal-content {
    background: $grayscale-900;
    border-radius: 4px 20px;
    padding-top: 16px;
    border: 1px solid #7a7a7a;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
}

.avatar-round {
    border-color: $color-primary;
    margin-left: 8px;
}

.modal-registro.ant-modal .ant-modal-close {
    position: absolute;
    top: 14px;
    inset-inline-end: 23px;
    z-index: 1010;
    padding: 0;
    color: $color-text;
    font-weight: 600;
    line-height: 1;
    text-decoration: none;
    border-radius: 4px;
    border-color: $color-text;
    width: 24px;
    height: 24px;
    border: none;
    outline: 0;
    cursor: pointer;
    transition:
        color 0.2s,
        background-color 0.2s;
}

.modal-registro.ant-modal .ant-modal-close:hover {
    color: $color-primary;
    background-color: none;
    text-decoration: none;
    border: none;
}

.card-form {
    background-color: $color-card-from;
    border-radius: 4px 20px;
}
.ant-form-item .ant-form-item-label > label {
    color: $input-label;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    content: "*";
    display: inline-block;
    margin-inline-end: 4px;
    color: $required;
    font-size: 14px;
    line-height: 1;
}
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label > label::after,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-24.ant-form-item-label > label::after,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-xl-24.ant-form-item-label > label::after {
    visibility: visible;
}

.ant-input-outlined {
    background: $input-color;
    border-width: 1px;
    border-color: $input-color;
    border-radius: 4px 20px;
}

/*.ant-input-outlined:hover {
    border-color: $color-primary;
    background-color: $input-color;
}*/

.ant-select-single .ant-select-selector {
    border-radius: 4px 20px;
}

.ant-checkbox {
    //border: 1px solid $color-primary;
    border-radius: 4px;
}
.ant-checkbox:hover {
    //border: 1px solid $color-primary !important;
    border-radius: 4px;
}

.ant-checkbox .ant-checkbox-inner {
    border: 1px solid $color-primary;
}

/*.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border: 2px solid #ff411c;
}

.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(
        .ant-pagination-size-changer
    )
    .ant-select-selector {
    border: 2px solid #ff411c;
}
*/
.ant-form-item-with-help .ant-form-item-explain {
    margin-top: 5px;
    height: auto;
    opacity: 1;
    background-color: #ff8282; //#FFD3D3
    border-radius: 4px 4px 4px 20px;
}

.ant-form-item .ant-form-item-explain-error {
    color: #3d3d3d;
    margin-left: 15px;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background: #ffd3d3;
}

.ant-menu-dark.ant-menu-submenu-popup > .ant-menu,
.ant-menu-dark > .ant-menu.ant-menu-submenu-popup > .ant-menu {
    background-color: $background;
    border: 2px solid $color-primary;
    border-top: none;
    border-radius: 0 0 20px 20px;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected:hover,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected:hover,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected:hover {
    background-color: $background;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark > .ant-menu .ant-menu-item-selected {
    background-color: $background;
}

.ant-tooltip {
    border-color: $tooltip-border !important;
}

.ant-tooltip .ant-tooltip-inner {
    border: 1px solid $color-primary;
}

.ant-tooltip .ant-tooltip-arrow::after {
    border: 1px solid $color-primary;
}

.ant-drawer .ant-drawer-content {
    background: $background;
}

.ant-menu-dark,
.ant-menu-dark > .ant-menu {
    background: $background;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0e9753;
    border-color: #0e9753;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: #0e9753;
    border-color: #0e9753;
}

.ant-float-btn-primary .ant-float-btn-body {
    background-color: $color-primary;
}

.ant-float-btn-primary .ant-float-btn-body:hover {
    background-color: $color-primary;
}

.espaciado {
    margin-left: 40px;
    margin-right: 30px;
}
.espaciados {
    margin-left: 40px;
    margin-right: 40px;
}
.margen-izquierda {
    margin-left: 50px;
}
.cabecera {
    margin-top: 40px;
}
.version-mobile {
    display: none;
}

.top-content {
    //margin-top: 140px;
    margin-left: 40px;
}
.text-second {
    font-size: 40px;
    line-height: 0.9;
    font-weight: bold;
}
.title-first {
    font-size: 48px !important;
}
.text-thris {
    font-size: 20px;
}
.margenes {
    margin-top: 40px;
    margin-left: 50px;
    //padding-right: 40px;
}
.row-buttons {
    margin-top: 60px;
    //padding: 0px 37px;
}

.header0-logo {
    margin-top: -40px;
}
.header-mobile {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1.5%;
    padding-top: 30px;
}

[class^="ant-form"],
[class*=" ant-form"] {
    font-family: "PT Serif";
}

.ant-input {
    font-family: "Degular";
}

/*.ant-menu-inline >.ant-menu-item, .ant-menu-vertical >.ant-menu-item, .ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title, .ant-menu-vertical >.ant-menu-submenu>.ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    margin-left: -22px;
    font-weight: bold;
}*/

.header-mobile {
    display: flex;
    align-items: center;
    background: $background;
    max-width: 1200px;
    margin: auto;
}

.header-mobile .ant-menu {
    background: transparent;
}

.logo {
    margin-left: 20px;
}

.ant-menu {
    color: $color-text;
    font-size: 18px;
    font-family: "PT Serif";
    font-weight: bold;
}

.divider-detalle {
    & .ant-divider-horizontal {
        display: flex;
        clear: both;
        width: 100%;
        min-width: 100%;
        margin: 1px 0;
    }
}

.divider {
    background-color: #f6fffd;
}

.submenu-list {
    margin-left: 109px;
}

.iconos-derecha {
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-dark > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
    color: $color-primary;
    background-color: $background;
}

.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-dark > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: $color-primary;
}

.term {
    color: #16d480;
    font-family: "Degular";
    right: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04em;
}
.ant-btn-variant-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #16d480;
    background: transparent;
}

.ant-select-dropdown {
    background-color: #f8e2da;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #515151;
    font-weight: 600;
    background-color: #effffb;
}
.ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: #16d480;
    background-color: transparent;
}

.ant-card .ant-card-body {
    //padding: 36px;
}

@media (max-width: 767px) {
    .btn-onboarding-g {
        width: 145px;
        height: 40px;
    }
    .btn-onboarding-disabled {
        height: 40px;
        margin-top: 24px;
    }
    .espaciado {
        margin-left: 0;
        margin-right: 0;
    }
    .espaciados {
        margin-left: 20px;
        margin-right: 20px;
    }
    .margen-espaecial {
        margin-top: 20px;
    }
    .margen-izquierda {
        margin-left: 0;
    }
    .cabecera {
        margin-top: 10px;
    }

    .ant-drawer .ant-drawer-header {
        border-bottom: 1px solid #7a7a7a;
    }

    .ant-menu .ant-menu-submenu,
    .ant-menu .ant-menu-submenu-inline {
        //border-bottom: 1px solid red;
        //width: 100%;
    }
    .ant-menu-inline .ant-menu-item {
        height: 80px;
        line-height: 80px;
    }
    .version-web {
        display: none;
    }
    .version-mobile {
        display: grid;
    }

    .top-content {
        margin-top: 80px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .text-second {
        font-size: 28px;
        font-weight: 500;
    }
    .title-first {
        font-size: 32px !important;
    }
    .text-thris {
        font-size: 16px;
    }
    .margenes {
        margin-left: 0;
    }

    .row-buttons {
        padding: 0;
    }
    .top-button-c {
        margin-top: 20px;
    }
    .btn-onboarding-m {
        background: $background;
        border: 2px solid $color-primary;
        border-radius: 4px 20px 4px 20px;
        color: $color-text;
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
    }

    .menu-footer {
        margin-left: -18px;
    }

    .term {
        color: #16d480;
        right: 13px;
        font-weight: bold;
    }
    .divider {
        display: none;
    }
    .ant-btn {
    }

    .menu-icon-circle {
        margin-top: -75px;
    }
}

.vertical-menu-container {
    width: 100%;
    background-color: #001529;
    min-height: 100vh;
    padding-top: 20px;
}

.vertical-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}

.menu-item {
    width: 100%;
    margin-bottom: 16px;
}

.menu-link {
    color: white;
    font-family: "PT Serif";
    font-size: 16px;
    display: block;
    padding: 8px 0;
    transition: color 0.3s ease;
}

.menu-link:hover {
    color: #1890ff;
}

.submenu {
    padding-left: 20px;
    margin-top: 8px;
}

.submenu-link {
    padding: 4px 0;
    font-size: 14px;
}

@media (min-width: 768px) {
    .vertical-menu-container {
        display: none;
    }
}

.containerDescripcion {
    @media (min-width: 1200px) {
        height: 250px;
    }

    @media (min-width: 1900px) {
        height: 180px;
    }
}

.card-tipo-producto {
    // desktop
    @media (min-width: 768px) {
        padding-right: 16px;
        padding-bottom: 15px;
    }

    //mobile
    @media (max-width: 767px) {
        padding-bottom: 15px;
    }
}
