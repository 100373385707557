@media (min-width: 768px) {
    .headers {
        padding: 20px 124px;
        color: #181818 !important;
        background-color: #181818 !important;

        ul li:nth-child(4) {
            padding-right: 0px;
        }
    }
}

/*.ant-menu-submenu-popup.ant-menu-submenu {
    background: transparent;
    left: 1186px !important;
}*/

@media (max-width: 767px) {
    .headers {
        padding: 0 24px;
        color: #181818 !important;
        height: 80px;
    }
}

.ant-drawer {
    .ant-drawer-body {
        padding: 0px 0px;
    }
}

.ant-drawer-header > {
    .ant-drawer-header-title {
    }
}

.containerlogo {
    height: 80px;
}

.menuMobile {
    .ant-menu-submenu-arrow {
        display: none;
    }

    .ant-menu-submenu-active.menu-mobile-subItem {
        .ant-menu-submenu-title {
            .ant-menu-title-content {
                color: #ff411c !important;
            }
        }
    }

    .menu-mobile-subItem.active {
        .ant-menu-submenu-title {
            .ant-menu-title-content {
                color: #ff411c !important;
            }
        }
    }

    .menu-mobile-subItem {
        .ant-menu-submenu-title {
            .ant-menu-title-content {
                color: #fffbfa !important;
                font-family: "PT Serif" !important;
                font-size: 18px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 24px !important;
                letter-spacing: 0.72px !important;
            }
        }
    }

    .ant-menu-submenu-active.menu-mobile-subItem {
        .ant-menu-submenu-title {
            .ant-menu-title-content {
                color: #ff411c !important;
            }
        }
    }

    .submenu-persona-active {
        a {
            color: #ff411c !important;
        }
    }

    .submenu-persona-empresa-active {
        a {
            color: #ff411c !important;
        }
    }

    .submenu-persona-empresa {
        height: 30px !important;
        border-bottom: 0px !important;

        a {
            color: #fffbfa;
            font-family: "Degular" !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 20px !important;
            letter-spacing: 0.56px !important;
        }
    }

    .submenu-persona {
        a {
            color: #fffbfa;
            font-family: "Degular" !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 20px !important;
            letter-spacing: 0.56px !important;
        }
    }

    .menu-mobile {
        .ant-menu-title-content {
            a {
                color: #fffbfa !important;
                font-family: "PT Serif" !important;
                font-size: 18px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 24px !important;
                letter-spacing: 0.72px !important;
            }
        }
    }

    .menu-mobile-contacto {
        .ant-menu-title-content {
            a {
                color: #fffbfa !important;
                font-family: "PT Serif" !important;
                font-size: 18px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 24px !important;
                letter-spacing: 0.72px !important;
            }
        }
    }

    .ant-menu-submenu-open {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }

    .menu-mobile-contacto {
        border-top: 1px solid #7a7a7a !important;
        border-radius: inherit !important;
    }

    .ant-menu-sub.ant-menu-inline {
        margin-top: -20px;
    }
    .submenu-persona {
        border-bottom: 0px !important;
        height: 35px !important;
    }

    .ant-menu.ant-menu-sub.ant-menu-inline {
        margin-bottom: 16px;
    }

    .submenu-persona-empresa {
        height: 30px !important;

        border-bottom: 0px !important;
    }

    .ant-menu-item {
        height: 80px;
        padding: 16px;
        border-bottom: 1px solid #7a7a7a;
        border-radius: inherit !important;
    }

    .ant-menu-submenu-title {
        height: 80px !important;
        padding: 16px;
    }
}

.copyright-mobile {
    font-family: "Degular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.64px;
    color: #fffbfa;
}

.btn-terminos-mobile {
    font-family: "PT Serif";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.64px;
    color: #16d480;
    padding-left: 0px;
}
