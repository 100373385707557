.row-home {
    padding: 154px 689px 0 124px;
}

.card-home {
    border: 2px solid #FF411C;
    background: none;
    border-radius: 4px 20px 4px 20px ;
    width: 627px;
    height: 592px;
    padding: 36px;
}

.card-home.ant-card .ant-card-body {
    padding: 0;
    border-radius: 0 0 8px 8px;
}
  
.circles-container {
    justify-content: flex-end; /* Por defecto, los botones se alinean al final */
}
.row-buttons {
    
    padding-right: 10px;
    padding-left: 51px;
}
@media (max-width: 767px) {
    .row-buttons {
        padding-right: 0;
        padding-left: 0;
    }
    .row-home {
        padding: 22px 20px
    }
    .card-home {
        border: 2px solid #FF411C;
        background: none;
        border-radius: 4px 20px 4px 20px ;
        width: auto;
        height: auto;
        padding: 36px;
    }
    .flex-container {
        justify-content: space-between; /* Logo y botones ocupan toda la fila */
    }
    
    .circles-container {
        //width: 100%; /* Los botones ocupan el ancho completo */
        justify-content: space-evenly; /* Botones centrados */
    }
    .row-buttons {
        margin-top: 48px;
    }
    .btn-onboarding {
        background: transparent;
        border: 2px solid #FF411C;
        border-radius: 4px 20px 4px 20px;
        color: #FFFBFA;
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        font-family: "PT Serif";
    }
    .btn-onboarding-persona {
        background: transparent;
        border-color: #FFFBFA;
        border-radius: 4px 20px 4px 20px;
        color: #FFFBFA;
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFBFA;
        border-color: #FFFBFA;
        font-family: "PT Serif";
    }
    .title-first {
        font-family: 'PT Serif';
        font-size: 32px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .text-second {
        font-family: 'PT Serif';
        font-size: 28px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
    .text-thris {
        font-family: 'Degular';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
}