.modal-term-cond {
    .countRomano{
        width: 24.5px !important;
        display: block;
        text-align: right;
    }

    .textoTitulo {
        font-family: "Degular";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.8px;
        color: #3d3d3d;
        padding-bottom: 16px;
    }

    .pointerSeparator {
        color: #3d3d3d !important;
        font-size: 4px;
        padding-top: 9px;
        display: block;
      }

    .margin-sin-scroll {
        padding-right: 10px;
    }

    .textoCuerpoTerminos {
        color: #515151;
        font-family: "Degular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.64px;
    }

    .tituloTerminos {
        color: #515151;
        font-family: "Degular";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.64px;
    }

    ::-webkit-scrollbar {
        height: 90px !important;
        width: 15px !important;
        background: #fffbfa !important;
    }

    ::-webkit-scrollbar-thumb {
        border-color: #fffbfa;
        background: #ff3700 !important;

        border-radius: 1ex !important;
        border-style: solid !important;
        border-width: 4px !important;
    }

    ::-webkit-scrollbar-track {
        border-radius: 0px !important;
        background: #fffbfa !important;
    }

    .ant-modal-content {
        border-radius: 4px 20px;
        background-color: #fffbfa;
    }

    .ant-modal-title {
        background-color: #fffbfa;
        text-align: center;
        padding-right: 40px;
    }

    .ant-modal-close {
        inset-inline-end: 20px;
    }

    .ant-modal-content {
        padding-right: 0px;
        padding-left: 24px;
        padding-bottom: 16px;
        padding-top: 16px;
    }
}

// .modal-term-cond.ant-modal .ant-modal-content {
//     border-radius: 4px 20px;
// }

// .modal-term-cond.ant-modal .ant-modal-close {
//     //     position: absolute;
//     //     top: 14px;
//     //     inset-inline-end: 23px;
//     //     z-index: 1010;
//     //     padding: 0;
//     //     color: #fffbfa;
//     //     font-weight: 600;
//     //     line-height: 1;
//     //     text-decoration: none;
//     //     border-radius: 4px;
//     //     border-color: #fffbfa;
//     //     width: 24px;
//     //     height: 24px;
//     //     border: none;
//     //     outline: 0;
//     //     cursor: pointer;
//     //     transition:
//     //         color 0.2s,
//     //         background-color 0.2s;
// }
